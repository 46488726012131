import React from "react";
import MainWrap from "../components/MainWrap";
import { Link } from "gatsby";

const settings = {
  name: "ENG",
  img1: "eng1.jpg",
  img2: "eng2.jpg",
  title: "ENG from Bijan Air, Inc.",
  heading: "ENG Services",
  subheadIndex: 5,
  subheadArrow: "Electronic News Gathering",
  subheadText:
    "Bijan Air has the expertise you need to add ENG to your company."
};

const EngPage = ({ location }) => (
  <MainWrap settings={settings} location={location}>
    <p>
      With over 10 years of Electronic News Gathering experience, Bijan Air has
      the expertise to provide quality, turn-key ENG helicopters to meet all
      your broadcasting needs.
    </p>

    <p>
      Consider a Bell Jet Ranger with a nose-mounted camera and built-in
      microwave system enabling live transmission and relay. This live
      broadcasting technology allows you to air up to-the-minute breaking news
      as well as live traffic reports. With a top speed of 120 MPH and range of
      2 hours, this helicopter is the tool you need to cover the breaking news
      in your area.
    </p>
    <p>Bijan Air can help make this a reality for your business.</p>
    <p>
      For more details please <Link to="/contact">contact us</Link>.
    </p>
  </MainWrap>
);

export default EngPage;
